<template>
  <div class="first">
    <div class="header">
      <van-nav-bar>
        <template #left>
          <van-image
            width="2rem"
            height="2rem"
            :src="require('../../assets/img/btc.png')"
            @click="openside"
          />
        </template>
        <template #title>
          <van-search
            v-model="search"
            clearable
            :left-icon="require('../../assets/img/search.png')"
            shape="round"
            :placeholder="$t('home.search')"
          />
        </template>
      <!--  <template #right>
          <van-icon
            name="chat-o"
            :badge="msgcount"
            @click="$router.push('/trade/contact/list')"
            v-if="msgcount > 0"
          />
          <van-icon
            name="chat-o"
            @click="$router.push('/trade/contact/list')"
            v-else
          />
        </template> -->
      </van-nav-bar>
    </div>
    <div class="maincontent">
      <!-- 轮播图 -->
      <div class="swipe">
        <van-swipe
          class="first-swipe"
          :autoplay="3000"
        >
          <van-swipe-item v-for="(item,index) in banner" :key="'banner' + index">
            <img :src="item.url" />
          </van-swipe-item>
          <template #indicator>
            <span></span>
          </template>
        </van-swipe>
      </div>
      <!-- 通知栏 -->
      <van-notice-bar
        scrollable
        :left-icon="require('../../assets/img/notice.png')"
        :text="lang === 'en' ? indexData.notice[0] : (indexData['notice_' + lang ] && indexData['notice_' + lang ][0])"
      />
      <!-- 三个产品 -->
      <div class="currency-information">
        <div
          class="information skiptranslate"
          v-for="item in product"
          :key="'product' + item.id"
          @click="totrade(item.pid)"
        >
          <span style="color: black;">
            <b style="margin-right: 10px;">{{ item.product_title }}</b>
            <span :style="{ color: item.change > 0 ? '#85b480' : '#b34f59' }">{{ item.change }}%</span><br />
            <span style="color: rgb(179, 79, 89); font-size: 25px;"><b>{{ item.current }}</b></span>
          </span>
        </div>
      </div>
      <!-- 快捷买币 -->
      <van-row class="currency">
        <van-row
          class="currency-shopping"
          @click="$router.push('/assets/recharge')"
        >
          <van-col
            span="4"
            class="currency-shopping-image"
          >
            <van-image
              width="3rem"
              :src="require('../../assets/img/quickbuy.png')"
            />
          </van-col>
          <van-col
            span="16"
            class="currency-shopping-left"
          >
            <b>{{ $t('home.quickbuy') }}</b>
            <br />
            <span>{{ $t('home.quicktext') }}</span>
          </van-col>
          <van-col
            span="4"
            class="currency-shopping-image"
          >
            <div>
              <img
                src="../../assets/img/icon-right.png"
                alt=""
                style="width: 40px; height: 40px;"
              />
            </div>
          </van-col>
        </van-row>
        <van-row
          class="currency-service"
          style="border-top: 1px solid rgb(243, 243, 243);"
        >
          <van-col
            span="8"
            class="currency-service-span"
            style="padding-right: 0.666667px;"
          >
            <div @click="toservice">
              <van-image
                width="2rem"
                height="2rem"
                :src="require('../../assets/img/icon-service.png')"
              />
              <span>{{ $t('home.serverurl') }}</span>
            </div>
          </van-col>
          <van-col
            span="8"
            class="currency-service-span"
            style="padding-left: 0.333333px; padding-right: 0.333333px;"
          >
            <div @click="tocourse">
              <van-image
                width="2rem"
                height="2rem"
                :src="require('../../assets/img/icon-course.png')"
              />
              <span>{{ $t('home.course') }}</span>
            </div>
          </van-col>
          <van-col
            span="8"
            class="currency-service-span"
            style="padding-left: 0.666667px; border-left: 1px solid rgb(243, 243, 243);"
          >
            <!--<div @click="$router.push('/trade/contact/list')">
              <van-image
                width="2rem"
                height="2rem"
                :src="require('../../assets/img/icon-message.png')"
              />
              <span>{{ $t('home.fund') }}</span>
            </div>-->
            <div @click="$openUrl(whatsAppUrl)">
              <van-image
                width="2rem"
                height="2rem"
                :src="require('../../assets/img/icon-message.png')"
              />
              <span>WhatsApp</span>
            </div>
          </van-col>
        </van-row>
      </van-row>
      <!-- 锁仓挖矿 -->
      <div class="mining" @click="tomining">
        <van-image width="3.53rem" height="2.28rem" :src="require('../../assets/img/mint-left.png')" />
        <div style="flex: 1;">
          <div class="mint-title1">{{$t('mining.title')}}</div>
          <div class="mint-title2">{{$t('mining.description')}}</div>
        </div>
        <van-image width="3.09rem" height="2.28rem" :src="require('../../assets/img/mint-right.png')" />
      </div>
      <!-- banner -->
      <van-row class="banner-image">
        <van-image :src="require('../../assets/img/hb-banner.png')" />
      </van-row>
      <!-- 产品列表 -->
      <van-tabs v-model="active" class="project-list">
        <van-tab :title="$t('trade.title3')">
            <van-row>
              <van-row class="currency-title">
                <van-col span="8"><span>{{ $t('home.hq') }}</span></van-col>
                <van-col span="8"><span>{{ $t('home.price') }}</span></van-col>
                <van-col span="8"><span>{{ $t('home.updown') }}</span></van-col>
              </van-row>
              <van-row style="background-color: white; width: 94%; margin: 0px auto;">
                <ul>
                  <van-row
                    v-if="item.product_type_id==2"
                    class="currency-Increase skiptranslate"
                    v-for="item in product"
                    :key="item.id"
                  >
                    <van-col span="8"><span>{{ item.product_title }}</span></van-col>
                    <van-col span="8"><span style="color:black"><b>{{ item.current }}</b></span></van-col>
                    <van-col span="8">
                      <van-button
                        class="currency-btn"
                        :style="{
                          backgroundColor: item.change > 0 ? '#85B480' : '#B34F59'
                        }"
                        @click="totrade(item.pid)"
                      >
                        {{ item.change }}%
                      </van-button>
                    </van-col>
                  </van-row>
                  <van-row style="margin:20px">
                    <span>{{ $t('common.nomore') }}</span>
                  </van-row>
                </ul>
              </van-row>
            </van-row>
        </van-tab>
        <van-tab title="USDT">
            <van-row style="margin: 0px auto; background-color: white; width: 94%; border-radius: 10px 10px 0px 0px;">
              <van-row class="currency-title">
                <van-col span="8"><span>{{ $t('home.hq') }}</span></van-col>
                <van-col span="8"><span>{{ $t('home.price') }}</span></van-col>
                <van-col span="8"><span>{{ $t('home.updown') }}</span></van-col>
              </van-row>
              <van-row style="background-color: white; width: 94%; margin: 0px auto;">
                <ul>
                  <van-row
                    v-if="item.product_type_id==1"
                    class="currency-Increase skiptranslate"
                    v-for="item in product"
                    :key="item.id"
                  >
                    <van-col span="8"><span>{{ item.product_title }}</span></van-col>
                    <van-col span="8"><span style="color:black"><b>{{ item.current }}</b></span></van-col>
                    <van-col span="8">
                      <van-button
                        class="currency-btn"
                        :style="{
                          backgroundColor: item.change > 0 ? '#85B480' : '#B34F59'
                        }"
                        @click="totrade(item.pid)"
                      >
                        {{ item.change }}%
                      </van-button>
                    </van-col>
                  </van-row>
                  <van-row style="margin:20px">
                    <span>{{ $t('common.nomore') }}</span>
                  </van-row>
                </ul>
              </van-row>
            </van-row>
        </van-tab>
      </van-tabs>

      <!-- 弹出层 -->
      <van-popup
        v-model="popshow"
        position="left"
        style="height:100%; width:80%"
      >
        <!-- 头部 -->
        <van-row class="personage-head">
          <van-row class="personage-head-image">
            <van-image
              width="3.1875rem"
              :src="require('../../assets/img/btc.png')"
            />
          </van-row>
          <van-row class="personage-head-span">
            <van-row @click="tosetting">
              <b v-if="user.nickname">{{ user.nickname }}</b>
              <b v-else>{{ $t('home.nickname') }}</b>
            </van-row>
            <van-row>
              <b>UID:{{ user.username }}</b>
            </van-row>
          </van-row>
        </van-row>
        <!-- vip -->
        <van-row class="personage-member">
          <van-row
            class="personage-member-a"
            @click="toservice"
          >
            <van-col
              span="5"
              style="margin-top: 5px;"
            >
              <van-image
                width="3rem"
                height="2.5rem"
                :src="require('../../assets/img/vip.png')"
              />
            </van-col>
            <van-col span="17"><span style="font-size: 15px; color: #fff;">{{
                $t('home.vip')
              }}</span></van-col>
            <van-col>
              <van-image
                width="1.3125rem"
                :src="require('../../assets/img/icon-right.png')"
              />
            </van-col>
          </van-row>
        </van-row>
        <!-- 列表 -->
        <van-row class="setting-list">
          <van-cell
            v-for="(item, index) in popshowSettingList"
            :key="item.title + index"
            :icon="item.icon"
            :title="item.title"
            is-link
            :to="item.url"
          >
            <template #right-icon>
              <van-icon size="10px" :name="require('../../assets/img/caret-right.png')" class="icon-caret" />
            </template>
          </van-cell>
          <!--<van-cell
            :title="$t('home.sf_auth')"
            is-link
            to="/person/authentication"
          >
            <template #icon>
              <van-icon :name="require('../../assets/img/icon-setting-1.png')" />
            </template>
          </van-cell>
          <van-cell
            icon="contact"
            :title="$t('home.yq_auth')"
            is-link
            to="/invite/inviteview"
          />
          <van-cell
            icon="smile-comment-o"
            :title="$t('home.gj_auth')"
            is-link
            to="/person/authentication"
          />
          <van-cell
            icon="service-o"
            :title="$t('home.serverurl')"
            is-link
            :url="serverurl"
          />
          <van-cell
            icon="question-o"
            :title="$t('home.help')"
            is-link
            to="/course"
          />
          <van-cell
            icon="setting-o"
            :title="$t('home.set')"
            is-link
            to="/person/setting"
          />-->
          <van-cell
            :icon="require('../../assets/img/icon-setting-6.png')"
            :title="$t('downloadurl')"
            is-link
            @click="opendownloadurl"
          >
            <template #right-icon>
              <van-icon size="10px" :name="require('../../assets/img/caret-right.png')" class="icon-caret" />
            </template>
          </van-cell>
          <van-cell
            class="changeLanguage"
            :icon="require('../../assets/img/icon-setting-7.png')"
            :title="$t('qie-huan-yu-yan')"
          >
            <template #extra>
              <van-dropdown-menu direction='up'>
                <van-dropdown-item v-model="selectLang" :options="$languages" @change="selectLangChange" />
              </van-dropdown-menu>
            </template>
          </van-cell>
        </van-row>
        <!-- 切换语言 -->
        <!-- <van-row
          class="personage-cut"
        > -->
          <!-- <span> {{ $t('home.change') }} </span>
          <i
            class="van-icon van-icon-arrow"
            style="margin-left: 5px; color: rgb(0, 78, 163);"
          ></i> -->
        <!-- </van-row> -->

        <!-- 退出登录 -->
        <van-row
          class="personage-button"
          @click="logout"
        >
          <div class="logout-btn">{{ $t('home.logout') }}</div>
        </van-row>
      </van-popup>
      <van-popup
        v-model="suiji"
        position="center"
        :close-on-click-overlay="false"
        style="width:80%;border-radius:8px; font-size:16px"
      >
        <h3 style="line-height:40px">{{$t('editpass.title')}}</h3>
        <van-cell-group>
          <van-field
            v-model="editpass.oldpass"
            center
            clearable
            type="password"
            :placeholder="$t('register.enterpassword')"
          />
          <van-field
            v-model="editpass.newpass"
            center
            clearable
            type="password"
            :placeholder="$t('qing-shu-ru-xin-mi-ma')"
          />
          <van-field
            v-model="editpass.newpass2"
            center
            clearable
            type="password"
            :placeholder="$t('qing-shu-ru-xin-mi-ma')"
          />
        </van-cell-group>
        <van-button
          style="width:100%"
          @click="passsubmit"
        >{{$t('common.confirm')}}</van-button>
      </van-popup>
    </div>
    <audio
      controls
      ref="notify"
      style="display:none"
    >
      <source src="msg.mp3" />
    </audio>
    <van-notify
      v-model="notifyshow"
      @click.native="$router.push('/trade/contact/list')">
      <span>{{$t('ni-you') }}{{ msgcount }}{{ $t('tiao-xin-xiao-xi') }}</span>
      <van-icon
        name="cross"
        style="position:absolute;right:1rem;"
        @click.stop="closenotify"
      />
    </van-notify>
  </div>
</template>
<script>
function setCookie(cname, cvalue, exdays) {
  var d = new Date()
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000)
  var expires = 'expires=' + d.toUTCString()
  document.cookie = cname + '=' + cvalue + '; ' + expires
}
setCookie('googtrans', '/auto/en', 100)
export default {
  data() {
    return {
      selectLang: localStorage.getItem('lang') || 'zh',
      // selectLangOption: [
      //   { text: '中文', value: 'zh' },
      //   { text: 'English', value: 'en' },
      //   { text: 'русский язык', value: 'ru' },
      //   { text: 'Français', value: 'fr' },
      //   { text: 'Español', value: 'es' },
      //   { text: 'Italiano', value: 'it' }
      // ],
      lang: localStorage.getItem('lang') || 'zh',
      search: '',
      // 弹出层 设置列表
      popshow: false,
      popshowSettingList: [
        {
          title: this.$t('home.sf_auth'),
          url: '/person/authentication',
          icon: require('../../assets/img/icon-setting-1.png')
        },
        {
          title: this.$t('home.yq_auth'),
          url: '/invite/inviteview',
          icon: require('../../assets/img/icon-setting-8.png')
        },
        {
          title: this.$t('home.gj_auth'),
          url: '/person/authentication',
          icon: require('../../assets/img/icon-setting-2.png')
        },
        {
          title: this.$t('home.serverurl'),
          url: this.serverurl,
          icon: require('../../assets/img/icon-setting-3.png')
        },
        {
          title: this.$t('home.help'),
          url: '/course',
          icon: require('../../assets/img/icon-setting-4.png')
        },
        {
          title: this.$t('home.set'),
          url: '/person/setting',
          icon: require('../../assets/img/icon-setting-5.png')
        }
      ],
      indexData: {
        notice: ['']
      },
      product: [],
      serverurl: '',
      whatsAppUrl: '',
      user: {},
      banner: {},
      suiji: false,
      editpass: {
        oldpass: '',
        newpass: '',
        newpass2: ''
      },
      msgcount: 0,
      notifyshow: false,
      websocket: null,
      wk: null,
      active: 0,
      downloadurl: ''
    }
  },
  created() {
    this.getdata()
    this.getmsgcount()
    // alert(this.lang)
  },
  mounted() {
    this.initwk()
  },
  methods: {
    opendownloadurl() {
      window.open(this.downloadurl)
    },
    // 获取未读信息数
    async getmsgcount() {
      const { data } = await this.$http.get('/home/home/msgcount')
      if (data) {
        if (data.code === 200) {
          this.msgcount = +data.data
          if (this.msgcount > 0) {
            this.notifyshow = true
            this.$refs.notify.play()
          }
        }
      }
    },
    // 获取首页数据
    async getdata() {
      const { data } = await this.$http.get('/home/home/index')
      if (data) {
        if (data.code === 200) {
          this.indexData = data.data
          this.product = data.data.product
          this.serverurl = data.data.serverurl
          this.whatsAppUrl = data.data.whatsapp_url || 'https://www.baidu.com/'
          this.user = data.data.user
          this.banner = data.data.banner
          this.downloadurl = data.data.downloadurl
          if (this.websocket === null) {
            this.initwebsocket()
          }
          if (data.data.user.random_password === '1') {
            this.suiji = true
          }
        }
      }
    },
    openside() {
      this.popshow = true
    },
    // 去教程
    tocourse() {
      this.$router.push('/course')
    },
    // 去理财页
    tofund() {
      this.$router.push('/fund')
    },
    // 去挖矿
    tomining() {
      this.$router.push('/miningStart')
    },
    // 去设置页
    tosetting() {
      this.$router.push('/person/setting')
    },
    // 客服链接
    toservice() {
      window.location.href = this.serverurl
    },
    // 去交易页
    totrade(id) {
      this.$router.push('/trade_' + id)
    },
    // 退出
    logout() {
      window.localStorage.removeItem('token')
      this.$router.push('/login')
    },
    async passsubmit() {
      if (
        !this.editpass.oldpass ||
        !this.editpass.newpass ||
        !this.editpass.newpass2
      ) {
        this.$toast.fail(this.$t('editpass.notice3'))
        return false
      }
      if (this.editpass.newpass !== this.editpass.newpass2) {
        this.$toast.fail(this.$t('editpass.eqpass'))
        return false
      }
      const { data } = await this.$http.post(
        '/home/home/editpass',
        this.editpass
      )
      if (data) {
        if (data.code === 200) {
          this.suiji = false
          this.getdata()
        } else {
          this.$toast.fail(this.getlang(data.msg, localStorage.getItem('lang')))
        }
      }
    },
    selectLangChange(value) {
      this.$i18n.locale = value
      localStorage.setItem('lang', value)
    },
    // 切换语言
    Language() {
      // function setCookie(cname, cvalue, exdays) {
      //   var d = new Date()
      //   d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000)
      //   var expires = 'expires=' + d.toUTCString()
      //   document.cookie = cname + '=' + cvalue + '; ' + expires
      // }
      // setCookie('googtrans', '', -1111111111111)
      setCookie('googtrans', '/auto/en', 100)
      if (this.$i18n.locale === 'zh') {
        this.$i18n.locale = 'en'
        localStorage.setItem('lang', 'en')
        // setCookie('googtrans', '/auto/en', 100)
      } else {
        this.$i18n.locale = 'zh'
        localStorage.setItem('lang', 'zh')
        // setCookie('googtrans', '/auto/zh', 100)
      }
      window.location.reload()
    },
    // 初始化连接
    initwebsocket() {
      this.websocket = new WebSocket(this.wsurl + '/wss:8880')
      this.websocket.onmessage = this.websocketonmessage
      this.websocket.onerror = this.websocketonerror
    },
    // 连接错误
    websocketonerror() {
      this.initwebsocket()
    },
    // 接收到数据
    websocketonmessage(e) {
      const message = JSON.parse(e.data)
      switch (message.type) {
        case 'init':
          this.binduid()
          break
        case 'text':
          this.getmsgcount()
          break
        case 'image':
          this.getmsgcount()
          break
      }
    },
    // 绑定uid
    binduid() {
      const msg = { type: 'bind', userid: this.user.id }
      this.websocketsend(JSON.stringify(msg))
    },
    // websorcket发送信息
    websocketsend(data) {
      this.websocket.send(data)
    },
    closenotify() {
      this.notifyshow = false
    },
    initwk() {
      this.wk = new WebSocket(this.wsurl + '/wssss:8080')
      this.wk.onmessage = this.wkonmessage
    },
    wkonmessage(e) {
      try {
        const data = JSON.parse(e.data)
        // console.log('行情:', data)
        // console.log(this.product)
        for (let key in data) {
          data[key].pid = this.product[key].pid
        }
        this.product = data
      } catch (error) {
        console.log(error)
      }
    }
  }
}
</script>
<style lang="less" scoped>
.header {
  width: 100%;
  ::v-deep {
    .van-nav-bar__title{
      max-width: 100%;
    }
  }
  .van-search__content--round {
    height: 2rem;
  }
  /deep/.van-search {
    padding: 0;
  }
  /deep/.van-icon {
    color: #b2b2b2;
  }
  /deep/.van-nav-bar__right {
    font-size: 1.5rem;
  }
  .van-info {
    min-width: 18px;
    font-size: 14px;
  }
}
.maincontent {
  padding: 50px 0;
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  font-size: 0.93333rem;
  // 轮播图
  .first-swipe {
    height: 10rem;
    .van-swipe-item img {
      width: 94%;
    }
  }
  .van-notice-bar {
    width: 94%;
    margin-left: 3%;
    background-color: #fff;
    color: #212121;
    border-radius: 5px;
  }
  //三个产品
  .currency-information {
    margin: 0.53333rem 0.8rem 0.53333rem 0.8rem;
    line-height: 2.66667rem;
    font-size: 0.93333rem;
    display: flex;
    white-space: nowrap;
    overflow-x: auto;
    .information {
      flex-shrink: 0;
      margin-right: 0.53333rem;
      width: 9.6rem;
      height: 4.26667rem;
      padding: 0.4rem;
      background-color: #fff;
      line-height: 1.6rem;
      border-radius: 0.26667rem;
    }
  }
  // 快捷买币
  .currency {
    width: 94%;
    margin-left: 3%;
    background-color: #fff;
    border-radius: 0.26667rem;
    overflow: hidden;
    .currency-shopping {
      background-color: #fff;
      color: #000;
      line-height: 1.46667rem;
      padding: 0.4rem;
      display: flex;
      align-items: center;
      justify-content: center;
      border-top-left-radius: 0.26667rem;
      border-top-right-radius: 0.26667rem;
      .currency-shopping-image {
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .currency-shopping-left {
        font-size: 0.9333rem;
        text-align: left;
        padding-left: 0.8rem;
        span {
          font-size: 0.61333rem;
        }
      }
    }
    .currency-service-span {
      border-bottom-left-radius: 0.26667rem;
      border-bottom-right-radius: 0.26667rem;

      div {
        padding: 0.4rem;
        background-color: #fff;
        display: flex;
        align-items: center;
        text-align: left;
        .van-image {
          padding: 0;
        }
        .van-col {
          float: left;
          box-sizing: border-box;
          min-height: 1px;
        }
        span {
          font-size: 0.8rem;
          margin-left: 0.53333rem;
        }
      }
    }
  }
  .mining {
    background: url("../../assets/img/mining-bg.png") no-repeat;
    background-size: cover;
    width: 94%;
    margin: 0.53333rem 3%;
    margin-bottom: 0;
    height: 4rem;
    border-radius: 0.19rem;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    .mint-title1{
      color: #fff;
      font-size: 1rem;
    }
    .mint-title2{
      color: #fff;
      font-size: 0.8rem;
    }
  }
  // banner
  .banner-image {
    width: 94%;
    margin: 0.53333rem 3%;
    .van-image {
      position: relative;
      display: inline-block;
    }
    /deep/.van-image__img {
      display: block;
      width: 100%;
      height: 100%;
      border-radius: 0.53333rem !important;
    }
  }
  // 产品列表
  .project-list{
    background: #ffffff;
    ::v-deep {
      .van-tab {
        font-size: 0.9375rem;
      }
      .van-tabs__line {
        background-color: #004EA3;
      }
    }
  }
  .currency-title {
    font-weight: 500;
    font-size: 0.875rem;
    color: #004EA3;
    padding: 1.25rem 0.625rem;
  }
  .currency-Increase {
    background-color: #fff;
    padding-top: 0.8rem;
    padding-bottom: 0.8rem;
    font-size: 1.12rem;
    height: 3.46667rem;
    border-bottom: 0.02667rem solid #e5e5e5;
    display: flex;
    align-items: center;
    .currency-btn {
      border: none;
      border-radius: 0.26667rem;
      width: 6.4rem;
      height: 2.13333rem;
      color: #fff;
      font-size: 1.12rem;
    }
  }
}
// 弹出层
.personage-head {
  //height: 18%;
  padding: 1.25rem 0.625rem 0.78125rem;
  display: flex;
  align-items: center;
  //justify-content: center;
  .personage-head-image {
    margin-right: 5%;
  }
  .personage-head-span {
    text-align: left;
    font-size: 1.125rem;
    line-height: 1.525rem;
    font-weight: 500;
  }
}
.personage-member {
  background: url("../../assets/img/mining-bg.png") no-repeat;
  background-size: cover;
  background-position: -10px 0;
  padding: 0.625rem 1.5625rem;
  .personage-member-a {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .van-col {
    float: left;
    box-sizing: border-box;
    min-height: 1px;
  }
}
//设置页 列表
.setting-list {
  ::v-deep {
    .van-cell{
      display: flex;
      align-items: center;
      .van-cell__title{
        font-size: 0.875rem;
        //禁止换行
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    .van-dropdown-menu__bar{
      box-shadow: none;
      height: initial;
    }
  }
}
// 列表区域
.van-cell {
  font-size: 1.06667rem;
  color: #000 !important;
  //height: 3.2rem;
  //letter-spacing: 0.26667rem;
}
.van-popup .van-icon {
  font-size: 1.2rem;
}
.van-cell__title {
  text-align: left;
  margin-left: 0.4rem;
}
// 切换语言
.personage-cut {
  width: 100%;
  position: fixed;
  bottom: 4rem;
  background-color: #f7f7f7;
  height: 2.66667rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.98667rem;
  color: #004ea3;
}
.changeLanguage {
  ::v-deep {
    .van-dropdown-item__option--active{
      color: #A30000;
      .van-dropdown-item__icon {
        color: #004ea3;
      }
    }
    .van-dropdown-menu__title--active{
      color: #004ea3;
    }
  }
}
// 退出登录
.personage-button {
  width: 100%;
  position: fixed;
  bottom: 0.63333rem;
  .logout-btn{
    display: inline-block;
    font-weight: 500;
    font-size: 1.125rem;
    padding: 0.625rem 0.9375rem;
    color: #FFFFFF;
    background: url("../../assets/img/logout-btn-bg.png") no-repeat;
    background-size: cover;
    border-radius: 5px;
  }
  b {
    font-size: 1.6rem;
    //letter-spacing: 0.48rem;
  }
}
</style>
